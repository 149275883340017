<style lang="scss">
.v-input__slot::placeholder{
    font-size: 14px !important;
}
.v-snack__content {
    align-items: center;
    display: flex;
    // color: black;
}
</style>
<template>
<!-- eslint-disable -->
  <div class="c-add-new-laboratory-test mt-5">
    <v-form
    ref="form"
    class="container-fluid container-info-basic"
    v-model="valid"
    lazy-validation
    >
        <v-row>
          <v-col class="text-left pt-0 "  cols="12">
            <p class="bold fs20">{{ $t('ensayos.agregar_ensayo') }}</p>
            <p class="fs16">
              {{ $t('ensayos.crear_edita') }}
            </p>
          </v-col>
        </v-row>
        <!-- nuevos campos -->
        <v-row class="mt-3">
          <v-col cols="12" class=" c-add-new-laboratory-test__step">
            <div class="d-flex align-items-center c-add-new-laboratory-test__step-number">
              <svg class="mr-3" id="Numeral"  width="20" height="20" viewBox="0 0 20 20">
                <g id="Elipse_146" data-name="Elipse 146" fill="#fff" stroke="#057aff" stroke-width="1.5">
                  <circle cx="10" cy="10" r="10" stroke="none"/>
                  <circle cx="10" cy="10" r="9.25" fill="none"/>
                </g>
                <text id="_1" data-name="1" transform="translate(6.171 14.139)" fill="#1a1a1a" font-size="12" font-family="OpenSans-Regular, Open Sans"><tspan x="0" y="0">1</tspan></text>
              </svg>
              <p class="fs16 bold mb-0 c-add-new-laboratory-test__step-title">{{ $t('ensayos.Basicinformation') }}</p>
            </div>
            <p class="ml-8 c-add-new-laboratory-test__step-subtitle text-left fs14 mb-0">{{ $t('ensayos.Pleasefill') }}</p>
          </v-col>
          
          <v-col cols="12" class="ml-8 pt-0 pb-0 c-add-new-laboratory-test__row">
            <v-row>
              <v-col cols="6" >
                <v-text-field
                  class="c-view-title__select"
                  v-model="form_data.data_ensayo.nombre"
                  :disabled="form_data.editable"
                  :rules="rules.name"
                  :label="$t('ensayos.nombre_ensayo')"
                  outlined
                  dense
                  require
                ></v-text-field>
              </v-col>
              <v-col cols="6" >
                  <span hidden>{{(form_data.data_ensayo.horas == 0) ? form_data.data_ensayo.horas = null : form_data.data_ensayo.horas}}</span>
                  <v-text-field
                    v-model="form_data.data_ensayo.horas"
                    :disabled="form_data.editable"
                    :label="$t('ensayos.tiempo_result')"
                    @keyup="activarIncubTime($event.target.value)"
                    outlined
                    dense
                    min="0"
                    type="number"
                    class="c-view-title__select"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" >
                  <v-text-field
                    v-model="form_data.data_ensayo.horas_incub"
                    :disabled="form_data.editable_incub"
                    :label="$t('ensayos.tiempo_incub')"
                   
                    @keyup="activarMessaje($event.target.value)"
                    activarMessaje
                    outlined
                    dense
                    min="0"
                    :max="form_data.data_ensayo.horas"
                    type="number"
                    class="c-view-title__select"
                  
                  ></v-text-field>
                  <p v-show="isMaxExceeded" class="error-message">El valor ingresado es mayor que {{ form_data.data_ensayo.horas }}</p>
                </v-col>
            </v-row>
            <v-row>
               <v-col cols="6" >
                <v-select
                    class="c-view-title__select"
                    :items="data_ensayo.tipo_ensayos"
                    v-model="form_data.data_ensayo.tipoEnsa"
                    :disabled="form_data.editable"
                    :label="$t('ensayos.tipo_ensayo')"
                    item-value="id_tipo_de_ensayo"
                    item-text="string_tipo_de_ensayo"
                    append-icon="mdi-chevron-down"
                    @change="activarItems($event)"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, index }">
                    <div class="p-0-5rem">
                      {{ $t(`${item.string_tipo_de_ensayo}`) }}
                    </div>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <div class="p-0-5rem">
                      {{ $t(`${item.string_tipo_de_ensayo}`) }}
                    </div>
                  </template>
                </v-select>

              </v-col>
              <v-col cols="6" >
                <v-text-field
                  class="c-view-title__select"
                  v-model="form_data.data_ensayo.descripcion"
                  :rules="rules.descripcion"
                  :disabled="form_data.editable"
                  :label="$t('ensayos.desc')"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    </v-form>
    <!-- snak -->
    <v-snackbar
      v-model="snack.snackbar"
      :vertical="snack.vertical"
      :color="snack.color"
      :top="true"
      :right="true"
      >
     <div v-if="snack.color == 'red'">
            {{ snack.txt }}
        </div>
        <div v-else class="c-snackbar__active">
            <svg class="mr-4" width="18.021" height="17.778" viewBox="0 0 18.021 17.778">
                <g id="check-circle" transform="translate(-1.039 -1.074)">
                <path id="Trazado_1" data-name="Trazado 1" d="M18,9.262V10a8,8,0,1,1-4.744-7.312" transform="translate(0 0)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Trazado_2" data-name="Trazado 2" d="M19.13,4l-7.793,7.8L9,9.463" transform="translate(-1.131 -0.443)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
            </svg>
            {{ snack.txt }}
        </div>
        <template v-slot:action="{ attrs }">
            <v-btn
                text v-bind="attrs"
                :color="snack.colorbtn"
                @click="snack.snackbar = false"
            >{{ $t('menu.Dismiss') }}
            </v-btn>
        </template>
    </v-snackbar>
    <!-- fin snack -->
    <!-- nuevo modal  -->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog_new"
          persistent
          max-width="400px"
           
        >

          <v-card>
            <v-card-title style="
                    padding-right: 25px !important;
                    padding-bottom: 0px !important;
                    padding-left: 32px !important;">
              <p class="bold mb-0 txt-enasyo">{{ $t('ensayos.tipo_ensayo') }}</p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12">
                    <v-text-field
                      :placeholder="$t('ensayos.Name_tipo_ensayo')"
                      outlined
                      v-model="name_test_type"
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-3 c-btn"
                color="#007AFF"
                outlined
                @click="dialog_new = false"
              >
                    {{ $t('ensayos.cerrar') }}
              </v-btn>
              <v-btn
                class="mr-3 c-btn c-btn--blue bold"
                outlined
                @click="newTesttype()"
              >
                {{ $t('ensayos.guardar') }}
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- fin nuevo modal -->
    <newsample ref="modalLaboratory"></newsample>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import i18n from '../../plugins/il8n';
import Service from '../../services/apis';

export default {
  name: 'Informacion',
  components: {
    newsample: () => import('./newlab.vue'),
  },
  computed: {
    ...mapState('Ensayos', ['form_data', 'selects', 'data_ensayo']),
    ens_tipo: {
      get() {
        let v = null;
        if (this.form_data.data_ensayo.externalizado) {
          v = '1';
        } else if (this.form_data.data_ensayo.BM) {
          v = '3';
        } else if (this.form_data.data_ensayo.MT) {
          v = '2';
        } else {
          v = '0';
        }
        return v;
      },
      set(value) {
        const auxData = this.form_data.data_ensayo;

        auxData.BM = 0;
        auxData.MT = 0;
        auxData.externalizado = 0;
        // // infoE
        switch (value) {
          case '1': auxData.externalizado = 1; break;
          case '2': auxData.MT = 1; break;
          case '3': auxData.BM = 1; break;
          default: break;
        }
        this.setDataEnsayo(auxData);
      },
    },
  },
  mounted() {
    // console.log('form data desde info Ensayo ', this.data_ensayo);
  },
  created() {
    this.activarIncubTime(this.form_data.data_ensayo.horas);
    // console.log('form data desde info Ensayo ', this.data_ensayo);
  },

  data: () => ({
    isMaxExceeded: false,
    timeout: 200000,
    valid: false,
    dialog_new: false,
    name_test_type: '',
    estatus: [{ id: 1, texto: i18n.t('ensayos.activo'), value: 1 }, { id: 2, texto: i18n.t('ensayos.inactivo'), value: 0 }],
    modologiaEnsayo: [
      {
        id: 0,
        value: 'Interno',
      },
      {
        id: 1,
        value: 'Comercial',
      },
    ],
    sitioAnalisis: [],
    // REGLAS
    rules: {
      tipoensa: [
        (v) => !!v || i18n.t('ensayos.rules_txt1'),
      ],
      name: [
        (v) => !!v || i18n.t('ensayos.rules_txt0'),
      ],
      tipometod: [
        (v) => (!!v || v === 0) || i18n.t('ensayos.rules_txt2'),
      ],
      sitioAnalisis: [
        (v) => !!v || i18n.t('ensayos.rules_txt3'),
      ],
    },
    snack: {
      snackbar: false,
      txt: null,
      color: 'red',
      error: false,
      x: null,
      y: 'top',
      colorbtn: '#fff',
    },
  }),
  methods: {
    ...mapMutations('Ensayos', ['setDataEnsayo', 'tipoEnsasel']),
    validate() {
      return this.$refs.form.validate();
    },
    openModallab() {
      this.$refs.modalLaboratory.openmodal();
    },
    openmodalTipoensayo() {
      this.$refs.modalLaboratory.openmodal();
    },
    activarItems(item) {
      // console.log('tipo ensayo', item);
      const items = this.data_ensayo.tipo_ensayos;

      const res = items.filter((tipo) => tipo.id_tipo_de_ensayo === item);
      // console.log(res);
      // si es otro abrimos el modal de registrar uno nuevo
      if (res[0].inlaca_tipo_ensayo.nombre === 'Other' && res[0].default === 1) {
        // console.log('creamos y lanzamos modal');
        this.dialog_new = true;
      }
      if (res.length > 0 && res[0].inlaca_tipo_ensayo.MT === 0) {
        this.tipoEnsasel(0);
      } else {
        this.tipoEnsasel(1);
      }
    },
    async newTesttype() {
      if (this.name_test_type.trim() !== '') {
        const nuevolab = await Service.apiToken('POST', 'new-tipoensayo', this.$ls.storage.token, { name: this.name_test_type });
        this.snack.snackbar = true;
        this.snack.color = '#F5F5F5';
        this.snack.colorbtn = '#007AFF';
        this.snack.txt = `${this.name_test_type} ${i18n.t('ensayos.save_succes')}`;
        // push al objeto de laboratorios
        this.data_ensayo.tipo_ensayos = nuevolab.data;
        this.name_test_type = '';
        // asignamos al select
        // this.form_data.data_ensayo.tipoEnsa =
        this.dialog_new = false;
      }
    },
    async activarIncubTime(time) {
      console.log(time);
      if (time !== 0 && time !== '') {
        this.form_data.editable_incub = false;
      } else {
        this.form_data.editable_incub = true;
      }
      this.activarMessaje(this.form_data.data_ensayo.horas_incub);
    },
    async activarMessaje(time) {
      if (time > this.form_data.data_ensayo.horas) {
        this.isMaxExceeded = true;
      } else {
        this.isMaxExceeded = false;
      }
    },
  },
};
</script>
